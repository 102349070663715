import React from "react"

const BioContent = () => (
  <div>
    <h1>Hello, I'm Justin Hong.</h1>
    <p>
      I am a third year Ph.D. student at Columbia University in the Computer
      Science Department advised by&nbsp;
      <a href="https://www.azizilab.com/">Professor Elham Azizi</a>. I am
      interested in developing scalable and easily interpretable computational
      methods for unraveling the complexities of biological data.
      <br />
      <br />
      Previously, I was a research engineer at the{" "}
      <a href="https://yoseflab.github.io/">Yosef Lab</a> at&nbsp;
      <a href="https://www.berkeley.edu/">UC Berkeley</a>, where I contributed
      to the open source package&nbsp;
      <a href="https://scvi-tools.org/" style={{ fontFamily: "monospace" }}>
        scvi-tools
      </a>{" "}
      for the deep-probabilistic modeling of single-cell omics data.
      <br />
      <br />I attended UC Berkeley for both undergraduate and graduate (M.S.)
      studies in Computer Science &#x26; Molecular and Cellular Biology. I was
      fortunate to have been advised by&nbsp;
      <a href="http://people.eecs.berkeley.edu/~yss/">
        Professor Yun Song
      </a> and{" "}
      <a href="http://people.eecs.berkeley.edu/~kannanr/">
        Professor Kannan Ramchandran
      </a>{" "}
      for my Master's program, during which I developed a method for
      deconvolving bulk RNA-seq data using a single-cell RNA-seq reference
      dataset.
      <br />
      <br />
      Previously, I worked as a software engineer at{" "}
      <a href="https://www.nuro.ai/">Nuro</a> working on developing evaluation
      and introspection tools for our autonomy stack. This involved filtering
      through massive amounts of data to understand where the bot underperformed
      and tracking the performance of future software iterations on relevant
      scenarios.
      <br />
      <br />
      Feel free to contact me at{" "}
      <span style={{ fontFamily: "monospace" }}>
        firstname dot lastname at columbia dot edu
      </span>
      .
    </p>
  </div>
)

export default BioContent
